.timeline-item .timeline-label {
    max-width: 90px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.timeline-label:before {
    content: "";
    left: 98px;
}
.timeline-label .timeline-badge {
    margin-left: 0px;
}
.dashboard-block .ra-no-data {
    color: #6c757d;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
}
.dashboard-block .ra-no-data svg {
    display: block;
    width: 55px;
    height: 55px;
    margin: 0 auto;
}
.timeline-label {
    width: 100%;
    height: 100%;
}
.max-height-410 {
    max-height: 410px;
}
.max-height-300 {
    max-height: 300px;
}
.card-xl-stretch.height-100 {
    height: 100%;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    display: none !important;
}
.apexcharts-tooltip-series-group {
    padding: 4px 15px !important;
}
.credit-chart .apexcharts-tooltip-marker {
    background-color: #50cd89 !important; 
}
button[data-status="false"] {
    background-color: #e7eef8 !important;
    border: 1px solid #d7e3f4 !important;
    box-shadow: 0 .8rem 3rem rgba(0,0,0,.075)!important;
    color: #000 !important;
}
button[data-status="true"] {
    border: 1px solid transparent !important;
    /* box-shadow: 0 .8rem 3rem rgba(0,0,0,.075)!important; */
}
button.state-1[data-status="false"] {
    background-color: #fff8ef !important;
    border: 1px solid #f1c180 !important;
}
button.state-2[data-status="false"] {
    background-color: #faf2f0 !important;
    border: 1px solid #e0987b !important;
}
button.state-3[data-status="false"] {
    background-color: #f4efff !important;
    border: 1px solid #aa7fd2 !important;
}
button.state-4[data-status="false"] {
    background-color: #ebf5fc !important;
    border: 1px solid #7bb8e4 !important;
}
.fa-genderless:before {
    content: "\f22d";
}
.dashboard-block .fs-3x {
    font-size: 2.6rem!important;
    font-weight: bolder !important;
}
.main-notification-block {
    display: block;
    width: 100%;
    height: 100%;
}
.credit-chart .card-body.credit-block {
    height: 150px;
}
.ctr-chart .card-body.crt-block {
    height: 156px;
}
.server-table-error {
    width: 250px;
    height: auto;
    font-size: 14px;
    margin-top: 13px;
    margin-left: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
}


/* ------- Alternate Style ------- */
.gauge-container {
    width: 200px;
    height: 200px;
    display: block;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: -100px;
    margin-top: -2px;
}
.gauge-container > .gauge .dial {
    stroke: #eee;
    stroke-width: 2;
    fill: rgba(0,0,0,0);
}
.gauge-container > .gauge .value {
    stroke: rgb(42, 160, 2);
    stroke-width: 2;
    fill: rgba(0,0,0,0);
}
.wrapper > .gauge-container {
    margin: 0;
}
.gauge-container > .gauge .dial {
    stroke: #dfdede;
    stroke-width: 3;
}
.gauge-container > .gauge .value {
    stroke: orange;
    stroke-dasharray: none;
    stroke-width: 3;
}
.gauge-container > .gauge .value-text {
    fill: #6c7293;
    font-weight: bold;
    font-size: 10px;
    font-family: Inter, Helvetica, "sans-serif";
}
.full-sent .gauge-container > .gauge .value {
    stroke: black !important;
}
.full-delivered .gauge-container > .gauge .value {
    stroke: #1bbc9b !important;
}
.full-pending .gauge-container > .gauge .value {
    stroke: #e05887 !important;
}
.head-chart .chart-title {
    color: #b3b3b3 !important;
    font-size: 12px !important;
    text-align: center;
    font-weight: 500;
}
.dashboard-stat .status-title {
    color: #aab5bc;
    font-weight: 500;
    font-size: 12px;
}
.dashboard-stat .status-count {
    font-size: 17px;
    font-weight: 600;
    color: #6c7293;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dashboard-stat {
    padding-top: 11px;
    margin-bottom: 5px;
    padding-left: 30px;
}
/* .dstats-block .col-md-6 .dashboard-stat:first-child {
    margin-bottom: 15px;
} */
.dashboard-stat .status-count span {
    font-size: 26px;
    display: inline-flex;
    margin-left: 3px;
}
.head-chart {
    height: 100%;
}
.cload {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 9px;
}
.cload i.fa {
    font-size: 34px;
    color: #d8d9e0;
}
.login-activities>.timeline-label>div:last-child>.separator {
    display: none;
}
.login-activities>.timeline-label>.d-flex {
    border-bottom: 1px dashed var(--kt-border-dashed-color);
    margin-bottom: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
.login-activities>.timeline-label>.d-flex:last-child {
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}
.top-20 {
    margin-top: -20px;
    height: 100%;
}

@media (max-width: 1300px) {
    .gauge-container {
        width: 140px;
        height: 170px;
        margin-bottom: -101px;
        margin-top: 5px;
    }
    .dashboard-stat .status-title {
        font-size: 10px;
    }
    .dashboard-stat .status-count {
        font-size: 14px;
    }
}

@media (max-width: 1444px) {
    .timeline-item .timeline-label {
        max-width: 50px;
    }
    .timeline-label .timeline-item .timeline-content {
        font-size: 12px;
    }
    .timeline-label:before {
        content: "";
        left: 58px;
    }
}