#preloading {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0, 0.5);
}
#preloading .preloader {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 40px);
}
.preloader {
    border: 8px solid #bababa;
    border-radius: 50%;
    border-top: 8px solid #fafafa;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    font-size: 0;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width:767px) {
    form.contact-us-form .css-vurnku {
        width: 100% !important;
    }
}