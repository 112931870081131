.toast {
    box-sizing: border-box;
    max-height: 100%;
    overflow: hidden auto;
    position: fixed !important;
    z-index: 1000;
    top:100px;
    right: 10px;
    height: 60px;
    transition: height 120ms ease 100ms;
    cursor: pointer;
}

.toast {
    font-size: 14px;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 18%) 0px 3px 8px;
    display: flex !important;
    margin-bottom: 8px;
    transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s, opacity 220ms ease 0s;
    width: 360px;
    transform: translate3d(0px, 0px, 0px);
}

.toastcheck {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    flex-shrink: 0;
    padding-bottom: 8px;
    padding-top: 10px;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 30px;
}

.taoast-content {
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 13px;
    line-height: 1.4;
    min-height: 40px;
    padding:12px;
    text-align: left;
}

.toast__dismiss {
    cursor: pointer;
    flex-shrink: 0;
    opacity: 0.5;
    padding: 6px;
    transition: opacity 150ms ease 0s;
}
.toast__dismiss:hover {
    opacity: 1;
}
.toast__dismiss svg {
    display: inline-block;
    vertical-align: text-top;
    fill: currentcolor;
}

.toast.success {
    background: #e3fcef;
    color: #006644;
}

.toast.success .toastcheck {
    background-color: #36b37e;
    color: rgb(227, 252, 239);
}

.toast.error {
    background: #ffebe6;
    color: #bf2600;
}

.toast.error .toastcheck {
    background-color: #ff5630;
    color: #ffebe6;
}

.toast.error .error-svg {
    display: inline-block;
}

.toast.error .success-svg {
    display: none;
}

.toast.success .error-svg {
    display: none;
}

.toast.success .success-svg {
    display: inline-block;
}

.css-animslide {
    animation: 5000ms linear 0s 1 normal none paused animationcolorslide;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0px;
    height: 0px;
    left: 0px;
    opacity: 1;
    position: absolute;
    width: 100%;
    display: block;
}

@keyframes animationcolorslide {
    0% {height: 100%;}
    100% {height: 0%;}
}