.sidebar-notifications .empty-loader {
    display: flex;
    width: 100%;
    height: auto;
    margin-top:70px;
    align-items: center;
    justify-content: center;
}
.sidebar-notifications .loading {
    border: 8px solid #e7e7e7;
    border-radius: 50%;
    border-top: 8px solid #bababa;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    font-size: 0;
}
.sidebar-notifications .ra-no-data {
    color: #6c757d;
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-top: 60px;
    text-align: center;
}
.sidebar-notifications .ra-no-data svg {
    display: block;
    width: 55px;
    height: 55px;
    margin: 0 auto;
}
.sidebar-notifications .alert-icon {
    width: 40px;
    padding-left: 5px;
}
.sidebar-notifications .alert-icon .svg-icon.svg-icon-1 svg {
    width: 26px !important;
    height: 26px !important;
}
.sidebar-notifications .text-warning {
    color: #665000 !important;
}
.sidebar-notifications .alert-warning {
    color: #665000;
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width:767px) {
    form.contact-us-form .css-vurnku {
        width: 100% !important;
    }
}