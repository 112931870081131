//
// Theme style
//

// Initialize
@import "init";

@include media-breakpoint-up(lg) {
    .menu-sub .menu-item .menu-link {
        margin-right: 0 !important;
        padding-left: 25px;
    }
    .menu-sub .menu-item .menu-sub .menu-item .menu-link {
        padding-left: 40px;
    }
    .flex-column-fluid {
        flex: 1 0 auto;
    }
    .app-main {
        display: flex;
        min-height: calc(100vh - 70px);
    }
    .app-footer .app-container {
        width: 100%;
    }
    .text-link {cursor: pointer;}
    .form-select.form-select-solid, .form-select.form-select-solid:focus {
        transition: color 0.2s ease;
        border: 1px solid #bec4d0 !important;
    }
    .form-control.form-control-solid {
        width: 100%;
        // background: #f5f8fa !important;
        border: 1px solid #bec4d0 !important;
    }
    .modal-header {padding: 15px 25px;}
    a.btn-xs {
        padding: calc(0.5rem + 1px) calc(1rem + 1px) !important;
        font-size: 0.9em;
    }
    .is-invalid>select.form-select, .is-invalid>select.form-select:focus, .is-invalid>input.form-control, .is-invalid>input.form-control:focus {
        border: 1px solid #f1416c !important;
    }
    .form-control.is-invalid, .was-validated .form-control:invalid {
        border: 1px solid #f1416c !important;
        padding-right: calc(1.5em + 0.75rem) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right calc(0.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.5em) calc(0.75em + 0.5em) !important;
    }
    .link-success {
        color: #0f997f !important;
    }
    .btn.btn-success {
        color: var(--kt-success-inverse);
        border-color: #0f997f;
        background-color: #0f997f;
    }
    .word-break {
        white-space: normal !important;
        word-break: break-all;
    }
    .max-400 {
        max-width: 400px;
    }
    .max-350 {
        max-width: 350px;
    }
    .max-250 {
        max-width: 250px;
    }
    .max-275 {
        max-width: 275px;
    }
    .btn-radius {
        border-radius: 1rem;
    }
    .bg-transparent {background: transparent !important;}
    .bg-transparent .bg-light-success {
        background-color: #d1eeea!important;
        border-radius: 1rem!important;
    }
    .bg-transparent:hover .bg-light-success {
        background-color: #edf8f7!important;
    }
    .bg-transparent .bg-light-info {
        background-color: #dad7f4!important;
        border-radius: 1rem !important;
    }
    .bg-transparent:hover .bg-light-info {
        background-color: #f0effb!important;
    }
    .svg-icon-3x {font-size: 3rem;}
    .summary-tab .text-success {  color: #46bcaa !important; }
    .summary-tab .text-muted {  color: #6c757d!important; }
    .summary-tab .bg-success { background-color: #46bcaa !important; }
    .summary-tab .bg-danger { background-color: #f35421!important; }
    .summary-tab .bg-warning { background-color: #ffcf52!important; }
    .summary-tab .bg-info { background-color: #4d69fa!important; }
    .summary-tab .bg-primary { background-color: #6c5dd3!important; }
    .summary-tab .bg-secondary { background-color: #ffa2c0!important; }
    .br-1 {border-radius: 1rem;}
    .br-2 {border-radius: 2rem;}
    .bx-s1 {box-shadow: 0 1rem 2rem #00000015}
    .btn.btn-active-primary.active {
        color: #009ef7;
        border: 1px solid #009ef7 !important;
        background-color: #f1faff !important;
    }
    .btn.btn-active-primary.active span.svg-icon {
        color: #009ef7 !important;
    }
    .btn.btn-active-primary.active:hover span.svg-icon {
        color: #FFF !important;
    }
    .text-right {text-align: right;} 
    .text-left {text-align: left;} 
    .hide {display: none;}
    .show {display: block;}
    .modal-dialog {
        max-width: 620px;
    }
    button[role="tab"] {
        color: #5e6278;
        font-size: 13px;
        font-weight: 600 !important;
        padding: 10px 20px;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: #dce3eb #dce3eb #dce3eb;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #181c32;
        border-color: #dce3eb #dce3eb #FFF;
    }
    .nav-tabs {
        border-bottom: 1px solid #dce3eb;
    }
    .input-group>input.form-control { width: auto; max-width: 300px; }
    a.btn.show { display: inline-block; }
    .image-input .image-input-wrapper {background-position: center;}
    .input-group .input-group-append button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .app-navbar .app-navbar-item .btn.show {
        display: inline-flex !important;
    }
    input.form-control[disabled] {
        background-color: #ebeff2 !important;
        color: #9a9fbd;
    }
    button.btn-block {
        width: 100%;
    }
    .price-flist .svg-icon.svg-icon-2 svg {
        min-width: 20px;
    }
    .price-flist .svg-icon.svg-icon-2 svg {
        min-width: 20px;
        width: 24px !important;
        height: 24px !important;
    }
    .price-flist .svg-icon.svg-icon-2 svg {
        min-width: 20px;
        width: 24px !important;
        height: 24px !important;
    }
    .border-3 {
        border: 3px solid !important;
    }
    .border-primary {
        border-color: #009ef7 !important;
    }
    .error {
        color: #f1416c;
        font-size: 12px;
        font-weight: 500;
        padding-left: 2px;
    }
    .alert-dark {
        background-color: var(--kt-app-bg-color);
    }
    .badge {
        text-align: left;
        white-space: normal;
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	#kt_app_content_container {
        height: calc(100vh)
    }
    .text-link {cursor: pointer;}
    .form-select.form-select-solid, .form-select.form-select-solid:focus {
        transition: color 0.2s ease;
        border: 1px solid #bec4d0 !important;
    }
    .form-control.form-control-solid {
        width: 100%;
        // background: #f5f8fa !important;
        border: 1px solid #bec4d0 !important;
    }
    .modal-header {padding: 15px 25px;}
    a.btn-xs {
        padding: calc(0.5rem + 1px) calc(1rem + 1px) !important;
        font-size: 0.9em;
    }
    .is-invalid>select.form-select, .is-invalid>select.form-select:focus, .is-invalid>input.form-control, .is-invalid>input.form-control:focus {
        border: 1px solid #f1416c !important;
    }
    .form-control.is-invalid, .was-validated .form-control:invalid {
        border: 1px solid #f1416c;
        padding-right: calc(1.5em + 0.75rem) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right calc(0.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.5em) calc(0.75em + 0.5em) !important;
    }
    .link-success {
        color: #0f997f !important;
    }
    .btn.btn-success {
        color: var(--kt-success-inverse);
        border-color: #0f997f;
        background-color: #0f997f;
    }
    .word-break {
        white-space: normal !important;
        word-break: break-all;
    }
    .max-400 {
        max-width: 400px;
    }
    .max-350 {
        max-width: 350px;
    }
    .max-250 {
        max-width: 250px;
    }
    .max-275 {
        max-width: 275px;
    }
    .btn-radius {
        border-radius: 1rem;
    }
    .bg-transparent {background: transparent !important;}
    .bg-transparent .bg-light-success {
        background-color: #d1eeea!important;
        border-radius: 1rem!important;
    }
    .bg-transparent:hover .bg-light-success {
        background-color: #edf8f7!important;
    }
    .bg-transparent .bg-light-info {
        background-color: #dad7f4!important;
        border-radius: 1rem !important;
    }
    .bg-transparent:hover .bg-light-info {
        background-color: #f0effb!important;
    }
    .svg-icon-3x {font-size: 3rem;}
    .summary-tab .text-success {  color: #46bcaa !important; }
    .summary-tab .text-muted {  color: #6c757d!important; }
    .summary-tab .bg-success { background-color: #46bcaa !important; }
    .summary-tab .bg-danger { background-color: #f35421!important; }
    .summary-tab .bg-warning { background-color: #ffcf52!important; }
    .summary-tab .bg-info { background-color: #4d69fa!important; }
    .summary-tab .bg-primary { background-color: #6c5dd3!important; }
    .summary-tab .bg-secondary { background-color: #ffa2c0!important; }
    .br-1 {border-radius: 1rem;}
    .br-2 {border-radius: 2rem;}
    .bx-s1 {box-shadow: 0 1rem 2rem #00000015}
    .btn.btn-active-primary.active {
        color: #009ef7;
        border: 1px solid #009ef7 !important;
        background-color: #f1faff !important;
    }
    .btn.btn-active-primary.active span.svg-icon {
        color: #009ef7 !important;
    }
    .btn.btn-active-primary.active:hover span.svg-icon {
        color: #FFF !important;
    }
    .text-right {text-align: right;} 
    .text-left {text-align: left;} 
    .hide {display: none;}
    .show {display: block;}
    button[role="tab"] {
        color: #5e6278;
        font-size: 13px;
        font-weight: 600 !important;
        padding: 10px 20px;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: #dce3eb #dce3eb #dce3eb;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #181c32;
        border-color: #dce3eb #dce3eb #FFF;
    }
    .nav-tabs {
        border-bottom: 1px solid #dce3eb;
    }
    .input-group>input.form-control { width: auto; max-width: 300px; }
    a.btn.show { display: inline-block; }
    .image-input .image-input-wrapper {background-position: center;}
    .input-group .input-group-append button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .app-navbar .app-navbar-item .btn.show {
        display: inline-flex !important;
    }
    input.form-control[disabled] {
        background-color: #ebeff2 !important;
        color: #9a9fbd;
    }
    button.btn-block {
        width: 100%;
    }
    .price-flist .svg-icon.svg-icon-2 svg {
        min-width: 20px;
        width: 24px !important;
        height: 24px !important;
    }
    .border-3 {
        border: 3px solid !important;
    }
    .border-primary {
        border-color: #009ef7 !important;
    }
    .error {
        color: #f1416c;
        font-size: 12px;
        font-weight: 500;
        padding-left: 2px;
    }
    .alert-dark {
        background-color: var(--kt-app-bg-color);
    }
    #kt_app_header_container {
        width: 100%;
    }
    .badge {
        text-align: left;
        white-space: normal;
    }
}
a.btn-fields {
    padding: 4px 10px!important;
    margin-bottom: 5px;
    font-size: 11px;
}
body#kt_app_body {
    height: 100% !important;
}
.menu-sub .menu-item .menu-link i.fa {
    font-size: 18px;
    margin-right: 10px;
    width: 18px;
    text-align: center;
    color: #3f4254;
}
.menu-state-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) i {
    color: var(--kt-primary);
}
.global-clock {
    font-size: 13px;
    font-weight: 600;
    color: var(--kt-text-muted);
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.global-clock i.fa {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    margin-right: 6px;
    color: var(--kt-text-muted);
}
.no-timeline:before {display:none;}
a.btn.download-file {
    padding: 3px 12px !important;
    font-size: 12px !important;
    margin-left: 9px;
}